import { Player } from '@lottiefiles/react-lottie-player'
import React from 'react'

const About = () => {
  return (
    <div id="about" className="container mx-auto">
      <div className="flex lg:flex-row flex-col-reverse justify-center align-middle">
        <div className="basis-1/2 grid align-middle px-9 lg:px-16 lg:ml-3">
          <h3 className="text-3xl lg:text-4xl font-mono mt-0 mb-5 lg:mb-0 text-start">
            Hi there
          </h3>
          <h3 className="text-md lg:text-lg font-mono mb-3 lg:mb-0">
            I am skilled in building UI and web applications using React.js. I
            have a strong understanding of JavaScript and Typescript.
            <br />
            <br />I am a proactive team-player, an excellent communicator, and a
            detail-oriented developer. I am always looking for new challenges
            and opportunities to learn and grow as a developer.
          </h3>
          <a href="#contact">
            <label className="text-md lg:text-xl font-mono font-bold underline">
              ➤ Send me a message
            </label>
          </a>
        </div>

        <div className="basis-1/2">
          <Player
            autoplay
            loop
            src="https://assets8.lottiefiles.com/packages/lf20_1elvfrxr.json"
            style={{ height: '', width: '' }}
          ></Player>
        </div>
      </div>
    </div>
  )
}

export default About
