import React from 'react'
import { Link } from 'react-router-dom'

const HeaderTop = () => {
  return (
    <div class="navbar px-9 py-9 justify-between">
      <li className="w-16">
        <a href="/">
          <img
            src="https://www.seekpng.com/png/full/71-717986_hyundai-n-logo-png.png"
            alt="logo"
          />
        </a>
      </li>
      <div className="navbar-end">
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z"
              />
            </svg>
          </label>
          <ul
            tabIndex={1}
            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 text-black"
          >
            <li>
              <a href="/#home">Home</a>
            </li>
            <li>
              <a href="/#projects">Projects</a>
            </li>
            <li>
              <a href="/#about">About Me</a>
            </li>
            <li>
              <a href="/#contact">Contact</a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://drive.google.com/file/d/1ZDxYx2qwdK9L3mneHS-q9N46FEv5a9sU/view?usp=sharing"
              >
                Download Resume
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/NaimurRahman3954"
              >
                GitHub
              </a>
            </li>
            <li className="">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/mnrahaman3954/"
              >
                LinkedIn
              </a>
            </li>
            <li className="">
              <Link to="/blog">Blog</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default HeaderTop
