import React from 'react'
import ImageGallery from 'react-image-gallery'

const Project1 = () => {
  const images = [
    {
      original:
        'https://i.ibb.co/8D8XrxV/Screenshot-2022-12-14-at-00-05-40-Recycle-Mart.png/id/1018/1000/600/',
      thumbnail:
        'https://i.ibb.co/8D8XrxV/Screenshot-2022-12-14-at-00-05-40-Recycle-Mart.png/id/1018/250/150/',
    },
    {
      original:
        'https://i.ibb.co/zRTXyx6/Screenshot-2022-12-14-at-00-05-54-Recycle-Mart.png/id/1015/1000/600/',
      thumbnail:
        'https://i.ibb.co/zRTXyx6/Screenshot-2022-12-14-at-00-05-54-Recycle-Mart.png/id/1015/250/150/',
    },
    {
      original:
        'https://i.ibb.co/sPsywxN/Screenshot-2022-12-14-at-00-06-05-Recycle-Mart.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/sPsywxN/Screenshot-2022-12-14-at-00-06-05-Recycle-Mart.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/pfKQPGf/Screenshot-2022-12-14-at-00-06-21-Recycle-Mart.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/pfKQPGf/Screenshot-2022-12-14-at-00-06-21-Recycle-Mart.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/sJgVrQv/Screenshot-2022-12-14-at-00-08-35-Recycle-Mart.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/sJgVrQv/Screenshot-2022-12-14-at-00-08-35-Recycle-Mart.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/vd4snVc/Screenshot-2022-12-14-at-00-08-48-Recycle-Mart.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/vd4snVc/Screenshot-2022-12-14-at-00-08-48-Recycle-Mart.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/wW1JLLH/Screenshot-2022-12-14-at-00-10-02-Recycle-Mart.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/wW1JLLH/Screenshot-2022-12-14-at-00-10-02-Recycle-Mart.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/HzXM18P/Screenshot-2022-12-14-at-00-10-26-Recycle-Mart.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/HzXM18P/Screenshot-2022-12-14-at-00-10-26-Recycle-Mart.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/6n0H7sT/Screenshot-2022-12-14-at-00-10-48-Recycle-Mart.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/6n0H7sT/Screenshot-2022-12-14-at-00-10-48-Recycle-Mart.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/q9N18V7/Screenshot-2022-12-14-at-00-08-59-Recycle-Mart.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/q9N18V7/Screenshot-2022-12-14-at-00-08-59-Recycle-Mart.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/9N0pm2J/Screenshot-2022-12-14-at-00-09-07-Recycle-Mart.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/9N0pm2J/Screenshot-2022-12-14-at-00-09-07-Recycle-Mart.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/ZTzDPLh/Screenshot-2022-12-14-at-00-09-23-Recycle-Mart.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/ZTzDPLh/Screenshot-2022-12-14-at-00-09-23-Recycle-Mart.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/Lk72V5d/Screenshot-2022-12-14-at-00-09-31-Recycle-Mart.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/Lk72V5d/Screenshot-2022-12-14-at-00-09-31-Recycle-Mart.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/K9s2zF1/Screenshot-2022-12-14-at-00-09-41-Recycle-Mart.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/K9s2zF1/Screenshot-2022-12-14-at-00-09-41-Recycle-Mart.png/id/1019/250/150/',
    },
  ]

  return (
    <div className="bg-slate">
      {/* 📌📌 Project 1 */}
      <div className="basis-1/2 h-full px-9 py-6">
        {/* Breadcrumb 🚧 */}
        {/* <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <a>Home</a>
            </li>
            <li>
              <a>Documents</a>
            </li>
            <li>Add Document</li>
          </ul>
        </div> */}
        <h1 className="text-4xl lg:text-5xl font-bold lg:mt-16 mb-9">
          Snapper (e-commerce website)
        </h1>
        <h5>
          <ul className="font-mono mb-6">
            <li>
              <span className="font-bold underline">Built with:</span> React,
              DaisyUI, Express, NodeJS, MongoDB
            </li>
            <br />
            <li>
              <span className="font-bold underline">Features include:</span>{' '}
              Firebase Authentication, Enhanced security with JWT & Environment
              Variables, Private Route, Admin Route, Seller’s Route, Dashboard,
              Seller Verification, Stripe Payment
            </li>
          </ul>
        </h5>

        <div className="flex flex-col lg:flex-row gap-3">
          <a
            href="https://developers-portal-d26e9.web.app/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-warning">Live Site</button>
          </a>
          <a
            href="https://github.com/NaimurRahman3954/recycle-mart-camera-shop-client"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-outline btn-success">
              GitHub (Client-Side)
            </button>
          </a>
          <a
            href="https://github.com/NaimurRahman3954/recycle-mart-camera-shop-server"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-outline btn-success">
              GitHub (Server-Side)
            </button>
          </a>
        </div>

        <div className="mt-16 pt-9 lg:w-[80vw]">
          <ImageGallery items={images} />
        </div>
      </div>
    </div>
  )
}

export default Project1
