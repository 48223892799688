import React from 'react'
import { Link } from 'react-router-dom'
import './NewProjects.scss'

const NewProjectsShowcase = () => {
  return (
    <div className="container mx-auto my-48">
      <h1 className="text-5xl lg:text-7xl text-center font-bold mb-16">
        Recent Projects
      </h1>
      <div className="grid grid-cols-12 gap-6 lg:px-16 px-9">
        {/* 📌📌 Project 1 */}
        <div className="col-span-12">
          <Link to="/Project1">
            <div className="transition duration-300 ease-in-out hover:scale-[1.02] project-wrapper">
              <div className="image-wrapped h-96 lg:h-[74vh]">
                <img
                  src="https://i.ibb.co/gPbhX7H/screencapture-localhost-3000-2023-02-07-00-53-08-1.png"
                  alt=""
                />
              </div>
              <div className="bg-[#201F23] p-6 image-text">
                <h3 className="text-xl font-bold mb-1">
                  Snapper (e-commerce website)
                </h3>
                <h5 className="text-sm font-mono text-gray-500">
                  React, TailwindCSS, Express, NodeJS, MongoDB, JWT, Stripe,
                  Firebase, Private Route, Admin Route
                </h5>
                <h6 className="hidden text-sm font-mono text-gray-500">
                  view project <span className="animate-pulse">→</span>
                </h6>
                {/* testing */}

                {/* testing */}
              </div>
            </div>
          </Link>
        </div>
        {/* 📌📌 Project 2 */}
        <div className="lg:col-span-6 col-span-full">
          <Link to="/Project2">
            <div className="transition duration-300 ease-in-out hover:scale-[1.02] project-wrapper">
              <div className="image-wrapped h-96">
                <img
                  src="https://i.ibb.co/FmX72Yg/Screenshot-2022-12-21-at-02-39-06-Cognition-1.png"
                  alt=""
                />
              </div>
              <div className="bg-[#201F23] p-6 image-text">
                <h3 className="text-xl font-bold mb-1">
                  Cognition (e-learning website)
                </h3>
                <h5 className="text-sm font-mono text-gray-500">
                  React, Express, NodeJS, MongoDB, Firebase, React-to-PDF
                </h5>
                <h6 className="hidden text-sm font-mono text-gray-500">
                  view project →
                </h6>
              </div>
            </div>
          </Link>
        </div>
        {/* 📌📌 Project 3 */}
        <div className="lg:col-span-6 col-span-full">
          <Link to="/Project3">
            <div className="transition duration-300 ease-in-out hover:scale-[1.02] project-wrapper">
              <div className="image-wrapped h-96">
                <img
                  src="https://i.ibb.co/dKDYsRq/Screenshot-2023-01-27-at-00-30-13-IELTS-Mentor.png"
                  alt=""
                />
              </div>
              <div className="bg-[#201F23] p-6 image-text">
                <h3 className="text-xl font-bold mb-1">
                  IELTS Mentor (digital service website)
                </h3>
                <h5 className="text-sm font-mono text-gray-500">
                  React, Express, NodeJS, MongoDB, Firebase, CRUD Operation
                </h5>
                <h6 className="hidden text-sm font-mono text-gray-500">
                  view project →
                </h6>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NewProjectsShowcase
