import React from 'react'
import { FaReact } from 'react-icons/fa'
import { DiStreamline } from 'react-icons/di'
import { BsCodeSlash } from 'react-icons/bs'
import './Skills.scss'

const Skills = () => {
  return (
    <div className="flex flex-col-reverse">
      <div className="w-full flex justify-center lg:-mt-24 -mt-20">
        <div className="lg:w-1/2 w-4/5 brightness-50">
          <img
            src="https://i.ibb.co/FKRGRDg/hello-world-html-code.png"
            alt=""
          />
        </div>
      </div>
      <div className="flex lg:flex-row flex-col justify-center align-middle pb-16 px-9 lg:px-20 z-20">
        <div className="border-2 p-9 basis-1/3">
          <div className="flex flex-col lg:flex-row lg:gap-6">
            <div className="text-7xl lg:mt-3">
              <BsCodeSlash></BsCodeSlash>
            </div>
            <h3 className="font-bold text-3xl drop-shadow-xl underlined u1 lg:mb-6">
              Software Development
            </h3>
          </div>
          <h3 className="text-md font-mono mt-6">
            SKilled in both functional and OOP: JavaScript, Typescript, Python,
            C.
          </h3>
        </div>
        <div className="border-2 p-9  basis-1/3">
          <div className="flex flex-col lg:flex-row lg:gap-6">
            <div className="text-7xl lg:mt-3">
              <FaReact></FaReact>
            </div>
            <h3 className="font-bold text-3xl drop-shadow-xl underlined u2 lg:mb-6">
              Frontend (ReactJS)
            </h3>
          </div>
          <h3 className="text-md font-mono mt-6">
            Passionate about UI/UX. Using HTML5, CSS3, Bootstrap, Tailwind CSS,
            React, R3F, Figma.
          </h3>
        </div>
        <div className="border-2 p-9 basis-1/3">
          <div className="flex flex-col lg:flex-row lg:gap-6">
            <div className="text-7xl lg:mt-3">
              <DiStreamline></DiStreamline>
            </div>
            <h3 className="font-bold text-3xl drop-shadow-xl underlined u3 lg:mb-6">
              Backend (MongoDB)
            </h3>
          </div>
          <h3 className="text-md font-mono mt-6">
            Comfortable with NodeJS, Express, Vercel, MongoDB, JWT, Netlify,
            Stripe.
          </h3>
        </div>
      </div>
      <h1 className="lg:text-7xl text-5xl text-center font-bold mb-12 mt-16">
        My Expertise
      </h1>
    </div>
  )
}

export default Skills
