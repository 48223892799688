import React from 'react'

const Footer = () => {
  const today = new Date()
  const year = today.getFullYear()
  return (
    <div className="text-center  my-16 font-mono">
      <span className="text-slate-400">&copy; {year} </span>
      <span className="underline">Naimur Rahman</span>
    </div>
  )
}

export default Footer
