import React from 'react'
import './Home.scss'
import Hero from './Hero'
import Projects from './Projects'
import Skills from './Skills'
import About from './About'
import Contact from '../Shared/Contact'
import NewProjectsShowcase from './newProjects/NewProjectsShowcase'

const Home = () => {
  return (
    <div className="pt-9">
      <Hero></Hero>
      <Skills></Skills>
      {/* <Projects></Projects> */}
      <NewProjectsShowcase></NewProjectsShowcase>
      <About></About>
      <Contact></Contact>
    </div>
  )
}

export default Home
