import React from 'react'
import ImageGallery from 'react-image-gallery'

const Project2 = () => {
  const images = [
    {
      original:
        'https://i.ibb.co/DQ53pH1/Screenshot-2022-12-14-at-01-00-07-Cognition.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/DQ53pH1/Screenshot-2022-12-14-at-01-00-07-Cognition.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/R765nnW/Screenshot-2022-12-14-at-01-01-00-Cognition.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/R765nnW/Screenshot-2022-12-14-at-01-01-00-Cognition.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/tQGT7wp/Screenshot-2022-12-14-at-01-01-22-Cognition.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/tQGT7wp/Screenshot-2022-12-14-at-01-01-22-Cognition.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/xScGYpV/Screenshot-2022-12-14-at-01-02-13-Cognition.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/xScGYpV/Screenshot-2022-12-14-at-01-02-13-Cognition.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/9Zdhdn8/Screenshot-2022-12-14-at-01-02-22-Cognition.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/9Zdhdn8/Screenshot-2022-12-14-at-01-02-22-Cognition.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/3Nw8GMp/Screenshot-2022-12-14-at-01-02-31-Cognition.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/3Nw8GMp/Screenshot-2022-12-14-at-01-02-31-Cognition.png/id/1019/250/150/',
    },
    {
      original:
        'https://i.ibb.co/W5YbVph/Screenshot-2022-12-14-at-01-02-40-Cognition.png/id/1019/1000/600/',
      thumbnail:
        'https://i.ibb.co/W5YbVph/Screenshot-2022-12-14-at-01-02-40-Cognition.png/id/1019/250/150/',
    },
  ]

  return (
    <div className="bg-slate">
      {/* 📌📌 Project 2 */}
      <div className="basis-1/2 h-full px-9 py-6">
        <h1 className="text-4xl lg:text-5xl font-bold lg:mt-16 mb-9">
          Cognition Learning Center (e-learning website)
        </h1>
        <h5>
          <ul className="font-mono mb-6">
            <li>
              <span className="font-bold underline">Built with:</span> HTML,
              CSS, React, Tailwind CSS, REST API, Vercel
            </li>
            <br />
            <li>
              <span className="font-bold underline">Features include:</span>{' '}
              Responsiveness, Firebase Authentication, Private Route, Courses on
              multiple tech stack, Blog
            </li>
          </ul>
        </h5>

        <div className="flex flex-col lg:flex-row gap-3">
          <a
            href="https://cognition-learning-center.web.app/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-warning">Live Site</button>
          </a>
          <a
            href="https://github.com/NaimurRahman3954/cognition-learning-center"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-outline btn-success">
              GitHub (Client-Side)
            </button>
          </a>
          <a
            href="https://github.com/NaimurRahman3954/cognition-learning-center-server"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-outline btn-success">
              GitHub (Server-Side)
            </button>
          </a>
        </div>

        <div className="mt-16 pt-9 lg:w-[80vw]">
          <ImageGallery items={images} />
        </div>
      </div>
    </div>
  )
}

export default Project2
