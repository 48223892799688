import { Player } from '@lottiefiles/react-lottie-player'
import React from 'react'
import { useForm } from 'react-hook-form'

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    const { name, email, message } = data

    console.log('Name: ', name)
    console.log('Email: ', email)
    console.log('Message: ', message)
  }

  return (
    <div id="contact">
      <div className="container mx-auto p-0 lg:p-12 pt-16">
        <div className="hero-content mx-auto flex-col-reverse lg:flex-row-reverse">
          <div className="text-left basis-1/2">
            <form action="">
              <div className="card mx-auto">
                <div className="card-body">
                  <h1 className="text-5xl font-bold mb-6">Send a message!</h1>
                  <div className="form-control">
                    <input
                      type="text"
                      placeholder="name"
                      className="input border-slate-400 input-bordered bg-transparent"
                    />
                  </div>
                  <div className="form-control">
                    <input
                      type="text"
                      placeholder="email"
                      className="input  border-slate-400 input-bordered bg-transparent"
                    />
                  </div>
                  <div className="form-control">
                    <textarea
                      className="textarea textarea-warning bg-transparent h-32"
                      placeholder="message"
                    ></textarea>
                  </div>

                  <div className="form-control mt-6">
                    <button className="btn btn-warning">Message</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="basis-1/2">
            <Player
              autoplay
              loop
              src="https://assets7.lottiefiles.com/packages/lf20_st6x0keq.json"
              style={{ height: '', width: '' }}
            ></Player>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
