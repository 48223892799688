import React from 'react'
import ImageGallery from 'react-image-gallery'

const Project3 = () => {
  const images = [
    {
      original:
        'https://i.ibb.co/852QR72/Screenshot-2022-12-14-at-01-18-46-IELTS-Mentor.png/id/1018/1000/600/',
      thumbnail:
        'https://i.ibb.co/852QR72/Screenshot-2022-12-14-at-01-18-46-IELTS-Mentor.png/id/1018/250/150/',
    },
    {
      original:
        'https://i.ibb.co/sHL69kX/Screenshot-2022-12-14-at-01-18-55-IELTS-Mentor.png/id/1018/1000/600/',
      thumbnail:
        'https://i.ibb.co/sHL69kX/Screenshot-2022-12-14-at-01-18-55-IELTS-Mentor.png/id/1018/250/150/',
    },
    {
      original:
        'https://i.ibb.co/Lk9PR5D/Screenshot-2022-12-14-at-01-19-03-IELTS-Mentor.png/id/1018/1000/600/',
      thumbnail:
        'https://i.ibb.co/Lk9PR5D/Screenshot-2022-12-14-at-01-19-03-IELTS-Mentor.png/id/1018/250/150/',
    },
    {
      original:
        'https://i.ibb.co/2nw568M/Screenshot-2022-12-14-at-01-19-13-IELTS-Mentor.png/id/1018/1000/600/',
      thumbnail:
        'https://i.ibb.co/2nw568M/Screenshot-2022-12-14-at-01-19-13-IELTS-Mentor.png/id/1018/250/150/',
    },
    {
      original:
        'https://i.ibb.co/jg2wVkL/Screenshot-2022-12-14-at-01-19-24-IELTS-Mentor.png/id/1018/1000/600/',
      thumbnail:
        'https://i.ibb.co/jg2wVkL/Screenshot-2022-12-14-at-01-19-24-IELTS-Mentor.png/id/1018/250/150/',
    },
    {
      original:
        'https://i.ibb.co/hHfpFZP/Screenshot-2022-12-14-at-01-19-30-IELTS-Mentor.png/id/1018/1000/600/',
      thumbnail:
        'https://i.ibb.co/hHfpFZP/Screenshot-2022-12-14-at-01-19-30-IELTS-Mentor.png/id/1018/250/150/',
    },
    {
      original:
        'https://i.ibb.co/kQrVjwv/Screenshot-2022-12-14-at-01-19-44-IELTS-Mentor.png/id/1018/1000/600/',
      thumbnail:
        'https://i.ibb.co/kQrVjwv/Screenshot-2022-12-14-at-01-19-44-IELTS-Mentor.png/id/1018/250/150/',
    },
    {
      original:
        'https://i.ibb.co/p3btR0H/Screenshot-2022-12-14-at-01-20-01-IELTS-Mentor.png/id/1018/1000/600/',
      thumbnail:
        'https://i.ibb.co/p3btR0H/Screenshot-2022-12-14-at-01-20-01-IELTS-Mentor.png/id/1018/250/150/',
    },
    {
      original:
        'https://i.ibb.co/R3fXQmR/Screenshot-2022-12-14-at-01-20-12-IELTS-Mentor.png/id/1018/1000/600/',
      thumbnail:
        'https://i.ibb.co/R3fXQmR/Screenshot-2022-12-14-at-01-20-12-IELTS-Mentor.png/id/1018/250/150/',
    },
    {
      original:
        'https://i.ibb.co/qr811tL/Screenshot-2022-12-14-at-01-20-22-IELTS-Mentor.png/id/1018/1000/600/',
      thumbnail:
        'https://i.ibb.co/qr811tL/Screenshot-2022-12-14-at-01-20-22-IELTS-Mentor.png/id/1018/250/150/',
    },
    {
      original:
        'https://i.ibb.co/K9pLtNj/Screenshot-2022-12-14-at-01-20-29-IELTS-Mentor.png/id/1018/1000/600/',
      thumbnail:
        'https://i.ibb.co/K9pLtNj/Screenshot-2022-12-14-at-01-20-29-IELTS-Mentor.png/id/1018/250/150/',
    },
    {
      original:
        'https://i.ibb.co/9gQ9yfS/Screenshot-2022-12-14-at-01-20-45-IELTS-Mentor.png/id/1018/1000/600/',
      thumbnail:
        'https://i.ibb.co/9gQ9yfS/Screenshot-2022-12-14-at-01-20-45-IELTS-Mentor.png/id/1018/250/150/',
    },
    {
      original:
        'https://i.ibb.co/xjz6vy8/Screenshot-2022-12-14-at-01-21-51-IELTS-Mentor.png/id/1018/1000/600/',
      thumbnail:
        'https://i.ibb.co/xjz6vy8/Screenshot-2022-12-14-at-01-21-51-IELTS-Mentor.png/id/1018/250/150/',
    },
    {
      original:
        'https://i.ibb.co/s5FkwK5/Screenshot-2022-12-14-at-01-21-58-IELTS-Mentor.png/id/1018/1000/600/',
      thumbnail:
        'https://i.ibb.co/s5FkwK5/Screenshot-2022-12-14-at-01-21-58-IELTS-Mentor.png/id/1018/250/150/',
    },
  ]

  return (
    <div className="bg-slate">
      {/* 📌📌 Project 3 */}
      <div className="basis-1/2 h-full px-9 py-6">
        <h1 className="text-4xl lg:text-5xl font-bold lg:mt-16 mb-9">
          IELTS Mentor (digital service website)
        </h1>
        <h5>
          <ul className="font-mono mb-6">
            <li>
              <span className="font-bold underline">Built with:</span> React,
              DaisyUI, Express, NodeJS, MongoDB
            </li>
            <br />
            <li>
              <span className="font-bold underline">Features include:</span>{' '}
              Firebase Authentication, Private Route, CRUD Operation, Category
              Page, Service Page, React-to-PDF, User Review
            </li>
          </ul>
        </h5>

        <div className="flex flex-col lg:flex-row gap-3">
          <a
            href="https://ielts-mentor-9c05a.web.app/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-warning">Live Site</button>
          </a>
          <a
            href="https://github.com/NaimurRahman3954/service-review"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-outline btn-success">
              GitHub (Client-Side)
            </button>
          </a>
        </div>

        <div className="mt-16 pt-9 lg:w-[80vw]">
          <ImageGallery items={images} />
        </div>
      </div>
    </div>
  )
}

export default Project3
