import { Player } from '@lottiefiles/react-lottie-player'
import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <div class="main-nav">
      <ul class="nav flex flex-col justify-between h-[100vh] pl-9 py-9">
        <div className="">
          <li className="w-16">
            <Link to={'/'}>
              <img src="https://i.ibb.co/0XYpRS4/logo2.png" alt="logo" />
            </Link>
          </li>
        </div>
        <div className="flex flex-col gap-3">
          <li>
            <div
              className="tooltip tooltip-right tooltip-success align-middle justify-center w-full"
              data-tip="Home"
            >
              <a href="/#home">
                <Player
                  hover
                  loop
                  src="https://assets7.lottiefiles.com/packages/lf20_HbCae5DbPf.json"
                  style={{ height: '70px', width: '70px' }}
                ></Player>
              </a>
            </div>
          </li>
          <li>
            <div
              className="tooltip tooltip-right tooltip-success align-middle justify-center w-full"
              data-tip="Projects"
            >
              <a href="/#projects">
                <Player
                  hover
                  loop
                  src="https://assets3.lottiefiles.com/packages/lf20_eu6c2WQbDN.json"
                  style={{ height: '70px', width: '70px' }}
                ></Player>
              </a>
            </div>
          </li>
          <li>
            <div
              className="tooltip tooltip-right tooltip-success align-middle justify-center w-full"
              data-tip="About Me"
            >
              <a href="/#about">
                <Player
                  hover
                  loop
                  src="https://assets1.lottiefiles.com/packages/lf20_HlycA6IxzS.json"
                  style={{ height: '70px', width: '70px' }}
                ></Player>
              </a>
            </div>
          </li>
          <li>
            <div
              className="tooltip tooltip-right tooltip-success align-middle justify-center w-full"
              data-tip="Contact"
            >
              <a href="/#contact">
                <Player
                  hover
                  loop
                  src="https://assets1.lottiefiles.com/packages/lf20_t3FSXlE85a.json"
                  style={{ height: '70px', width: '70px' }}
                ></Player>
              </a>
            </div>
          </li>
        </div>
        <div className="flex flex-col gap-3">
          <li>
            <div
              className="tooltip tooltip-right tooltip-success align-middle justify-center w-full"
              data-tip="Download Resume"
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://drive.google.com/file/d/1ZDxYx2qwdK9L3mneHS-q9N46FEv5a9sU/view?usp=sharing"
              >
                <Player
                  hover
                  loop
                  // src="https://lottie.host/ce1afe69-342c-49f1-9cc5-60a4f5079ab7/BR3AOyN4Qg.json"
                  src="https://assets1.lottiefiles.com/packages/lf20_sO32AYofqV.json"
                  style={{ height: '30px', width: '30px' }}
                ></Player>
              </a>
            </div>
          </li>
          <li>
            <div
              className="tooltip tooltip-right tooltip-success align-middle justify-center w-full"
              data-tip="GitHub"
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/NaimurRahman3954"
              >
                <Player
                  hover
                  loop
                  src="https://assets5.lottiefiles.com/packages/lf20_E9xOa0dy50.json"
                  style={{ height: '50px', width: '50px' }}
                ></Player>
              </a>
            </div>
          </li>
          <li className="">
            <div
              className="tooltip tooltip-right tooltip-success align-middle justify-center w-full"
              data-tip="Linkedin"
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/mnrahaman3954/"
              >
                <Player
                  hover
                  loop
                  src="https://assets1.lottiefiles.com/packages/lf20_XwRoHmpcws.json"
                  style={{ height: '50px', width: '50px' }}
                ></Player>
              </a>
            </div>
          </li>
          <li>
            <div
              className="tooltip tooltip-right tooltip-success align-middle justify-center w-full"
              data-tip="Visit Blog"
            >
              <Link to="/blog">
                <Player
                  hover
                  loop
                  src="https://lottie.host/c5d83399-9bb4-4a72-b8ec-bce78be8f9f9/TZjwzCR18u.json"
                  style={{ height: '35px', width: '35px' }}
                ></Player>
              </Link>
            </div>
          </li>
        </div>
      </ul>
    </div>
  )
}

export default Header
