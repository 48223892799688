import './App.scss'
import React from 'react'
import Router from './Routes/Router'
import { RouterProvider } from 'react-router-dom'

function App() {
  return (
    <div className="">
      <RouterProvider router={Router}></RouterProvider>
    </div>
  )
}

export default App
