import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import Main from '../Layout/Main'
import PageNotFound from '../Pages/404/PageNotFound'
import Blog from '../Pages/Blog/Blog'
import Home from '../Pages/Home/Home'
import Project1 from '../Pages/Home/Project1'
import Project2 from '../Pages/Home/Project2'
import Project3 from '../Pages/Home/Project3'

const Router = createBrowserRouter([
  {
    path: '/',
    element: <Main></Main>,
    children: [
      {
        path: '/',
        element: <Home></Home>,
      },
      {
        path: '/home',
        element: <Home></Home>,
      },
      {
        path: '/project1',
        element: <Project1></Project1>,
      },
      {
        path: '/project2',
        element: <Project2></Project2>,
      },
      {
        path: '/project3',
        element: <Project3></Project3>,
      },
      {
        path: '/blog',
        element: <Blog></Blog>,
      },
    ],
  },
  {
    path: '*',
    element: <PageNotFound></PageNotFound>,
  },
])

export default Router
