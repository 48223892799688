import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../Pages/Shared/Footer'
import Header from '../Pages/Shared/Header'
import HeaderTop from '../Pages/Shared/HeaderTop'

const Main = () => {
  return (
    <div className="flex">
      <div className="sticky top-0 h-fit hidden lg:block">
        <Header></Header>
      </div>
      <div className="w-full">
        <div className="lg:hidden sm:block">
          <HeaderTop></HeaderTop>
        </div>
        <Outlet></Outlet>
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Main
