import React, { useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { TypeAnimation } from 'react-type-animation'

function Box(props) {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef()
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false)
  const [clicked, click] = useState(false)
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame(
    (state, delta) => (ref.current.rotation.x = ref.current.rotation.y += delta)
  )
  // Return the view, these are regular Three.js elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={ref}
      scale={clicked ? 1.1 : 1}
      onClick={(event) => click(!clicked)}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}
    >
      <boxGeometry args={[2.5, 2.5, 2.5]} />
      <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
    </mesh>
  )
}

const Hero = () => {
  return (
    <div
      className="w-full mx-auto pl:0 lg:pl-16 pt-16 h-[100vh] lg:h-screen"
      id="home"
    >
      <div className="lg:block absolute scale-75 lg:scale-100 top-6 lg:top-16 right-9 lg:right-6 h-96 w-96 bg-slate-">
        <Canvas>
          <ambientLight intensity={0.5} />
          <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
          <pointLight position={[-10, -10, -10]} />
          {/* <Box position={[-1.2, 0, 0]} /> */}
          <Box position={[0, 0, 0]} />
          <OrbitControls />
        </Canvas>
      </div>

      <div className="lg:h-[70vh] h-[50vh] grid place-content-center">
        <h1 className="text-4xl lg:text-5xl font-bold mb-6 font-mono">
          Naimur Rahman
        </h1>
        <div className="flex align-middle">
          <h1 className="lg:text-8xl text-6xl font-bold mr-6">Designer</h1>
          <span className="divider primary-content bg-[#FABD22] h-[2px] w-16 lg:w-72 md:w-24 lg:mr-16 my-auto"></span>
        </div>

        <h1 className="lg:text-8xl text-6xl font-bold mb-12 flex">
          <span className="text-slate-500">+</span>
          <span>
            <TypeAnimation
              sequence={[
                'Developer',
                2000,
                'Engineer',
                2000,
                'Visualizer',
                2000,
                'Creator',
                2000,
              ]}
              style={{ fontSize: '' }}
              wrapper="h2"
              repeat={Infinity}
            />
          </span>
        </h1>
      </div>

      <div className="grid grid-cols-1 place-content-center lg:mr-12">
        <div className="mouse mx-auto"></div>
        <p className="mx-auto mt-6">Scroll</p>
      </div>
    </div>
  )
}

export default Hero
