import { Player } from '@lottiefiles/react-lottie-player'
import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../Shared/Footer'
import Header from '../Shared/Header'
import HeaderTop from '../Shared/HeaderTop'

const PageNotFound = () => {
  return (
    <div>
      <div className="flex">
        <div className="sticky top-0 h-fit hidden lg:block">
          <Header></Header>
        </div>
        <div className="w-full">
          <div className="lg:hidden sm:block">
            <HeaderTop></HeaderTop>
          </div>
          <div className="hero-content text-center h-[90vh]">
            <div className="max-w-lg">
              <Player
                autoplay
                loop
                src="https://assets1.lottiefiles.com/packages/lf20_owua8tsy.json"
                style={{ height: '', width: '' }}
              ></Player>
              <h5 className="py-6 font-mono">
                The webpage you are looking for does not exist!
              </h5>
              <Link to="/#projects">
                <button className="btn btn-warning btn-outline">
                  Explore Projects
                </button>
              </Link>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound
