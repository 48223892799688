import { Player } from '@lottiefiles/react-lottie-player'
import React from 'react'
import { Link } from 'react-router-dom'

const Blog = () => {
  return (
    <div>
      <div className="grid place-items-center text-center h-[90vh]">
        <div className="max-w-lg">
          <Player
            autoplay
            loop
            src="https://lottie.host/b7b7e7e2-1530-43a3-b794-47efa4b14045/a6l1JQw6c7.json"
            style={{ height: '', width: '' }}
          ></Player>
          <h5 className="py-6 font-mono">Still working on it. Stay tuned!</h5>
          <Link to="/#projects">
            <button className="btn btn-warning btn-outline">
              Explore Projects
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Blog
